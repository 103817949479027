import { HiOutlineMailOpen, HiOutlineChevronLeft } from "react-icons/hi";
import { useState, useRef, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import isEmail from "validator/lib/isEmail";
import emailjs from '@emailjs/browser'



const timer = () => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve('done')
    }, process.env.REACT_APP_TIMERS)
  })
}


function Message({children, modalMsj}) {
  console.log(modalMsj)

  const background = {
    background: `${modalMsj === 'Message sent' ? 'rgb(145, 135, 233)' : 'rgb(206, 131, 104)'}`
  }

  return <div className="modal" style={background}>
    <span>{children}</span>
  </div>
}


function Email() {
  
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [modalMsj, setModalMsj] = useState('')
  const form = useRef()


  const llamaTimer = async() => {
    console.log(process.env.REACT_APP_TIMERS)
    await timer()
    setModal(false)
  }


  const clearFields = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }


  
  const emptyFields = 'mal'
  const wrongEmail = 'email incorrect'

  
  const submitForm = async(e) => {
    e.preventDefault()
    try {
      if(!name || !email || !subject || !message) throw emptyFields
      if(!isEmail(email)) throw wrongEmail
      setLoading(true)
      const sendEmail = await emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_API_KEY)
      console.log(sendEmail)
      setModalMsj('Message sent')
      setModal(true)
      llamaTimer()
      clearFields()
      setLoading(false)
      llamaTimer()
    } catch (err) {
      console.log(err)
      console.log(err.status)
      setModal(true)
      if(err.status === 0){
        setModalMsj('Smething went wrong...')
        llamaTimer()
        return
      }
      setModalMsj(err === 'mal' ? 'Please complete all the fileds' : 'Invalid email')
      llamaTimer()
      return
    } 
  }



  return <div className="email">
    <div className="email__content">
      <div className="email__content-title">
        <HiOutlineMailOpen className="icon"/>
        <h3>Send me an Email</h3>
        <NavLink to='/'>
          <HiOutlineChevronLeft className="icon"/>
        </NavLink>
        
      </div>
      <form className="form" onSubmit={submitForm} ref={form}>
        <div className="form__group">
          <input 
            type="text" 
            id="name" 
            placeholder="Name" 
            name="from_name" 
            className="inputForm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
            
          <input 
            type="email" 
            id="email" 
            placeholder="Email" 
            name="from_email" 
            className="inputForm"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <input 
          type="text" 
          id="subjet" 
          placeholder="Subject" 
          name="subject" 
          className="inputForm"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <textarea 
          name="message" 
          id="message" 
          cols="30" 
          rows="10" 
          className="inputForm textArea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        >  
        </textarea>
        <button>{loading ? 
          <span className="loader"></span>
          : 'Send Message'}</button>

      </form>
    </div>
    {modal ? <Message modalMsj={modalMsj}>{modalMsj}</Message> : ''}
  </div>  
}


export default Email